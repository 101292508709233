<template>
  <div class="OrderDetails">
    <div class="Details_box">
      <!-- 顶部 -->
      <div
        class="back"
        @click="
          $router.push({
            name: 'OrderCenter',
            params: {
              page: page * 1,
            },
          })
        "
      >
        ﹤ 订单中心
      </div>
      <!-- 主体 -->
      <div class="main_body">
        <!-- 订单顶部 -->
        <div class="main_top_box">
          <div class="serial_box">
            订单编号: <span> {{ itemObj.orderNumber }} </span>
          </div>
          <div class="place_date_box">下单时间</div>
          <div class="number_box">数量</div>
          <div class="money_box">金额</div>
        </div>
        <!-- 订单底部 -->
        <div
          class="main_bottom_box"
          v-for="(item, index) in itemObj.productInfos"
          :key="index"
        >
          <div class="serial_box">
            <div class="img_box">
              <img
                :src="
                  item.productFiles
                    ? $store.state.onlineBasePath + item.productFiles
                    : require('assets/images/no-shop-img80.png')
                "
                alt=""
              />
            </div>
            <div class="item_name">{{ item.productName }}</div>
          </div>
          <div class="place_date_box">
            {{ itemObj.createTime.substring(0, 10) }}
          </div>
          <div class="number_box">{{ item.productNum }}</div>
          <div class="money_box">¥ {{ item.productPrices }}</div>
        </div>
      </div>

      <!-- 底部 -->
      <div class="bottom_part">
        <div class="bottom_box">
          <div class="text">
            <div class="item_box">支付时间:</div>

            <div class="item">{{ itemObj.payedTime }}</div>
          </div>
          <div class="text">
            <div class="item_box">商品总额:</div>

            <div class="item">¥ {{ itemObj.orderPrice }}</div>
          </div>
          <div class="text">
            <div class="item_box">限时优惠:</div>
            <div class="item">
              - ¥
              {{
                (
                  itemObj.orderPrice -
                  (itemObj.payPrice == null
                    ? itemObj.orderPrice
                    : itemObj.payPrice)
                ).toFixed(2)
              }}
            </div>
          </div>
          <div class="text">
            <div class="item_red_box">实付数:</div>
            <div class="item_red">
              ¥
              {{
                itemObj.payPrice == null ? itemObj.orderPrice : itemObj.payPrice
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDetails",
  data() {
    return {
      // 订单
      itemObj: JSON.parse(this.$route.query.item),
      page: this.$route.query.page,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.OrderDetails {
  .Details_box {
    .back {
      padding: 20px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }

  .main_body {
    //   顶部
    .main_top_box {
      display: flex;
      padding: 10px 20px;
      background-color: #f8f8f8;
      font-weight: 800;
      font-size: 12px;
      .serial_box {
        flex: 40%;
        span {
          color: #4588ff;
          margin-left: 10px;
        }
      }
      .place_date_box {
        flex: 20%;
        text-align: center;
      }

      .number_box {
        flex: 20%;
        text-align: center;
      }

      .money_box {
        flex: 20%;
        text-align: center;
      }
    }
    // 底部
    .main_bottom_box {
      display: flex;
      padding: 20px 20px;
      font-size: 14px;
      border-bottom: 2px solid #fcfcfc;

      .serial_box {
        flex: 40%;
        display: flex;

        .img_box {
          margin-right: 20px;
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
      .place_date_box {
        flex: 20%;
        text-align: center;
      }

      .number_box {
        flex: 20%;
        text-align: center;
      }

      .money_box {
        flex: 20%;
        text-align: center;
      }
    }
  }

  .bottom_part {
    padding: 20px 20px 0 20px;

    overflow: auto;

    .bottom_box {
      float: right;
      width: 400px;
    }
    .text {
      padding: 10px;
      align-items: center;
      display: flex;
      font-size: 15px;
      .item_box {
        width: 30%;
        text-align: right;
      }
      .item {
        width: 70%;
        text-align: right;
      }

      .item_red_box {
        width: 30%;
        color: red;
        text-align: right;
      }
      .item_red {
        width: 70%;
        color: red;
        text-align: right;
        font-weight: 800;
        font-size: 18px;
      }
    }
  }
}
</style>
